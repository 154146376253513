import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as fcl from '@onflow/fcl';
import './HeaderNew.css';
import logo from '../../assets/Images/logo_new.png'

export default function CustomNavbar() {
  const [user, setUser] = useState({ loggedIn: false, addr: null });
  const [isNavOpen, setNavOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = fcl.currentUser().subscribe((user) => {
      setUser({
        loggedIn: user.loggedIn,
        addr: user.addr,
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logout = () => {
    fcl.unauthenticate();
    navigate('/');
  };

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  
  return (
    <header className='header'>
      <div className='header-item'>
        <Link to='/'>
          <img className='logo' src={logo}alt='Logo' />
        </Link>
      </div>
      <nav className={`header-item nav-links ${isNavOpen ? 'open' : ''}`}>
        <Link to='/'>HOME</Link>
        <Link to='/mint'>MINT</Link>
        <Link to='/vroom'>SHOP</Link>
        <Link to='/garage'>GARAGE</Link>
        <Link to='/racing'>RACE</Link>
        <Link to='/wager'>WAGER</Link>
        <Link to='/fantasy'>FANTASY</Link>
        <Link to='/turbo'>TURBO</Link>
        <Link to="/pitcrew" >PITCREW</Link>
        <Link to="/toolbox" >TOOLBOX</Link>
        {/*<Link to="/terms" >TERMS</Link>*/}

      </nav>
      <div className='header-item sign-in'>
        {user.loggedIn ? (
            <>
              <p className="mx-4 mb-0 mt-2" style={{color: "#3a6e48"}}>{user.addr}</p>
              <Button
                className="mx-4 sign-in-btn"
                onClick={() => logout()}
              >
                Sign out
              </Button>
            </>
          ) : (
            <Button
              className="mx-5 sign-in-btn"
              onClick={() => {
                console.log('Sign In button clicked');
                fcl.logIn();
              }}
            >
              Sign In
            </Button>
          )
        }
      </div>
      
        <Button className='nav-toggle-btn' onClick={toggleNav} aria-expanded={isNavOpen} aria-controls='nav-links'>
          <span className='sr-only'>Navigation</span>
          <span className={`icon ${isNavOpen ? 'open' : ''}`} />
        </Button>
      
    </header>
  );
}