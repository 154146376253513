export const getAllNFTs = `
import MetadataViews from 0x1d7e57aa55817448
import NonFungibleToken from 0x1d7e57aa55817448
import DriverzNFTStorefront from 0xf887ece39166906e
import DriverzAirdrop from 0xf887ece39166906e
import Wheel from 0xf887ece39166906e
import Helmet from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import Car from 0xf887ece39166906e
import DriverzNFT from 0xa039bd7d55a96c0c

pub struct NFTData {
    pub let nftId: UInt64
    pub let nftType: String
    pub let thumbnail: String
    pub let name: String
    pub let description: String
    pub let listed: Bool
    pub let price: UFix64?
    pub let listingId: UInt64

    init(nftId: UInt64, nftType: String, thumbnail: String, name: String, description: String, listed: Bool, price: UFix64?, listingId: UInt64) {
        self.nftId = nftId
        self.nftType = nftType
        self.thumbnail = thumbnail
        self.name = name
        self.description = description
        self.listed = listed
        self.price = price
        self.listingId = listingId
    }
}

pub fun main(address: Address): [NFTData] {
    let nftData: [NFTData] = []

    let account = getAccount(address)

    //HELMET NFTS CHECK
    if(account.getCapability<&Helmet.Collection{Helmet.CollectionPublic}>(Helmet.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&Helmet.Collection{Helmet.CollectionPublic}>(Helmet.CollectionPublicPath).borrow()!
        let helmetIds = marketCollectionRef.getIDs()

        for nftID in helmetIds {
            let nft = marketCollectionRef.borrowArt(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<Helmet.HelmetMetadata>())!
            let display = view as! Helmet.HelmetMetadata
            let nftID = nft.id
            let url = "https://ipfs.io/ipfs/".concat(display.ipfsLink)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Helmet"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    //Tires NFTS CHECK
    if(account.getCapability<&DriverzAirdrop.Collection{DriverzAirdrop.CollectionPublic}>(DriverzAirdrop.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&DriverzAirdrop.Collection{DriverzAirdrop.CollectionPublic}>(DriverzAirdrop.CollectionPublicPath).borrow()!
        let driverzAirdropIds = marketCollectionRef.getIDs()

        for nftID in driverzAirdropIds {
            let nft = marketCollectionRef.borrowArt(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<DriverzAirdrop.DriverzAirdropMetadata>())!
            let display = view as! DriverzAirdrop.DriverzAirdropMetadata
            let nftID = nft.id
            let url = "https://ipfs.io/ipfs/".concat(display.ipfsLink)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Tires"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    //Wheel NFTS CHECK
    if(account.getCapability<&Wheel.Collection{Wheel.CollectionPublic}>(Wheel.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&Wheel.Collection{Wheel.CollectionPublic}>(Wheel.CollectionPublicPath).borrow()!
        let wheelIds = marketCollectionRef.getIDs()

        for nftID in wheelIds {
            let nft = marketCollectionRef.borrowArt(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<Wheel.WheelMetadata>())!
            let display = view as! Wheel.WheelMetadata
            let nftID = nft.id
            let url = "https://ipfs.io/ipfs/".concat(display.ipfsLink)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Wheel"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    //CARCLUB NFTS CHECK
    if(account.getCapability<&CarClub.Collection{CarClub.CollectionPublic}>(CarClub.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&CarClub.Collection{CarClub.CollectionPublic}>(CarClub.CollectionPublicPath).borrow()!
        let carClubIds = marketCollectionRef.getIDs()

        for nftID in carClubIds {
            let nft = marketCollectionRef.borrowCarClub(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<CarClub.CarClubMetadata>())!
            let display = view as! CarClub.CarClubMetadata
            let nftID = nft.id
            let url = "https://ipfs.io/ipfs/".concat(display.image)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Car Club"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    //CAR NFTS CHECK
    if(account.getCapability<&Car.Collection{Car.CollectionPublic}>( Car.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&Car.Collection{Car.CollectionPublic}>( Car.CollectionPublicPath).borrow()!
        let carIds = marketCollectionRef.getIDs()

        for nftID in carIds {
            let nft = marketCollectionRef.borrowArt(id: nftID) 
                        ?? panic("Could not borrow a reference to the collection")


            let view = nft.resolveView(Type<Car.CarMetadata>())!
            let display = view as! Car.CarMetadata
            let nftID = nft.id
            let url = "https://ipfs.io/ipfs/".concat(display.ipfsLink)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Car"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    //DRIVERZ NFTS CHECK
    if(account.getCapability<&{NonFungibleToken.CollectionPublic}>(DriverzNFT.CollectionPublicPath).borrow() != nil){
        let marketCollectionRef = account.getCapability<&{NonFungibleToken.CollectionPublic}>(DriverzNFT.CollectionPublicPath).borrow()!
        let carIds = marketCollectionRef.getIDs()

        for nftID in carIds {
            let nft = marketCollectionRef.borrowNFT(id: nftID) 
                        


            let view = nft.resolveView(Type<MetadataViews.Display>())!
            let display = view as! MetadataViews.Display

            let nftID = nft.id
            let uri = display.thumbnail.uri().slice(from: 7, upTo: display.thumbnail.uri().length)
            let url = "https://ipfs.io/ipfs/".concat(uri)
            var price: UFix64? = nil
            var listed: Bool = false
            let nftType = "Genesis"
            var listingId: UInt64 = 0

            if(account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow() != nil){
                let mktCollection = account.getCapability<&DriverzNFTStorefront.Storefront{DriverzNFTStorefront.StorefrontPublic}>(DriverzNFTStorefront.StorefrontPublicPath).borrow()!
                for listingID in mktCollection.getListingIDs(){
                    if(mktCollection.borrowListing(listingResourceID: listingID) != nil){
                    let listing = mktCollection.borrowListing(listingResourceID: listingID)!
                        if(listing.getDetails().nftID == nftID){
                            price = listing.getDetails().salePrice
                            listed = true
                            listingId = listingID
                        } 
                    }
                }
            }
            nftData.append(NFTData(nftId: nftID, nftType: nftType ,thumbnail: url, name: display.name, description: display.description, listed: listed, price: price, listingId: listingId))
        }
    }

    return nftData
}

`