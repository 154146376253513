import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { FaDiscord, FaTwitter, FaMedium, FaInstagram } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Footer.css";

export default function Footer(){
  const [show, setShow] = useState(false);
  const form = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0s9y9va', 'template_uu4n4wf', form.current, 'jvwWHVtC43-cTAivJ')
    .then((result) => {
      console.log(result);
      toast.success("Successfully sent an mail!");
      handleClose();
    }, (error) => {
      console.log(error.text);
      toast.error(error.text);
      handleClose();
    });
  };

  return (
    <>
      <div className="footer-container">
        <div className="footer-logos">
          <img
            className="me-3"
            alt=""
            src="./FLOW_BANNER_CLEAN.gif"
            width="250"
            height="40"
          />
          <img
            className="mb-3"
            alt=""
            src="./dapper-logo.png"
            width="150"
            height="35"
          />
        </div>
  
        <div className="footer-social">
          <a className="me-4" href="https://discord.gg/driverz">
            <FaDiscord className="text-white" size={20} />
          </a>
          <a className="me-4" href="https://twitter.com/DriverzWorld">
            <FaTwitter className="text-white" size={20} />
          </a> {/*}
          <a className="me-4" href="https://medium.com/@driverzf1nft">
            <FaMedium className="text-white" size={20} />
  </a> */}
          <a href=" https://www.instagram.com/driverzworld">
            <FaInstagram className="text-white" size={20} />
          </a>
        </div>
  
        <div className="footer-text">
          <p className="text-white mt-3 mb-0">
            © 2023 Driverz Inc.
            All Rights Reserved.
          </p>
        </div>
  
        <div className="footer-contact">
          <p className="text-white font-weight-bold" onClick={handleShow} role="button">
            Contact Us
          </p>
        </div>
  
        <div className="footer-terms">
          <Link className="text-white font-weight-bold terms-conditions" to="/terms">
            Terms & Conditions / Privacy Policy
          </Link>
        </div>
      </div>
    </>
  );
  
  /*
  
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" name="user_name" required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" name="user_email" required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} 
                placeholder="Please write a description here." name="message" required/>
            </Form.Group>

            <Button className="me-2" variant="primary" type="submit">
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>            
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>  */
  
}