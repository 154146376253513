export const checkVroomBalance = `

import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e

pub fun main(address: Address): UFix64 {
    let vaultRef = getAccount(address).getCapability<&{FungibleToken.Balance}>(/public/VroomTokenBalance)
        .borrow()
        ?? panic("Could not borrow reference to the vault!")

    return vaultRef.balance
}
`
