import { useState, useEffect } from "react";
import * as fcl from "@onflow/fcl";
import { Card, Button, Alert, InputGroup, Form, Modal } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';

import l_tree from '../../assets/Images/Linktree.png'
import merch from '../../assets/Images/Merch.png'
import flwscr from '../../assets/Images/Flowscore.png'
import ftsy from '../../assets/Images/Fantasy.png'
import g_cal from '../../assets/Images/Google-Calendar.png'
import dntn from '../../assets/Images/Donation.png'
import plbk from '../../assets/Images/Playback.png'
import bckgd from '../../assets/Images/Toolbox.jpg'
import initGen from '../../assets/Images/Initialize.jpg'
import exclusive from '../../assets/Images/CarClub353.png'
import vroom from '../../assets/Images/Vroom_Init_1.png';
// import {initGenesis} from '../../Cadence/initGenesis'
import { initHelmet } from '../../Cadence/initHelmet';
import { initCar } from '../../Cadence/initCar';
import { initAll } from '../../Cadence/Transactions/initAll';

import './Tools.css'
//import ExclusiveSeriesPage from "./ExclusiveSeriesPage"




export default function Tools(){
    const [user, setUser] = useState({ loggedIn: null });
    const [showExclusiveSeries, setShowexclusiveSeries] = useState(false);
    const [userInput, setUserInput] = useState("");


    useEffect(() => {
        fcl.currentUser.subscribe(setUser);
      }, []);



    const initAccount = async () => {
        try {
            const txid = await fcl.mutate({
                cadence: initHelmet,
                args: (arg, t) => [],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            });

            toast.success("Successfully initialized Genesis Collection")
        } catch (error) {}
    };

    const initAllCollections = async () => {
        try {
            const initAllCollec = await fcl.mutate({
                cadence: initAll,
                args: (arg, t) => [],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999
            })
        } catch (error) {}
    };

    const initStarterPack = async () => {
        try {
            const StPkInit = await fcl.mutate({
                cadence: initCar,
                args: (arg, t) => [],
                proposer: fcl.currentUser,
                payer: fcl.currentUser,
                authorizations: [fcl.currentUser],
                limit: 999,
            })
        } catch (error) {}
    };

    useEffect(() => {
        const checkSecretPhrase = () => {
            if (userInput.toLowerCase() === "frostexclusive") {
                setShowexclusiveSeries(true);
            }
        };

        document.addEventListener("keydown", checkSecretPhrase);

        return () => {
            document.removeEventListener("keydown", checkSecretPhrase);
        };
    
    });

    if (showExclusiveSeries) {
        return (
          <div className="tools-page">
            <div className="first-row">
              <img src={bckgd} alt="" className="tools-full-width-image" />
            </div>
    
            <div className="column-tile">
              <img src={exclusive} alt="" style={{ cursor: "pointer" }} onClick={() => initAccount()} />
              <h3>Initialize Driverz Exclusive Series</h3>
              <p>Click on the icon to set up your account to receive Driverz Exclusive collectibles.</p>
            </div>
          </div>
        );
      }

    return(

        <div className="tools-page">
        
            <div className="first-row">
                <img src={bckgd} alt="" className='tools-full-width-image'/>
            </div>

            <div className="second-row">
                <div className="column-tile tile1">
                    <a href='https://linktr.ee/DriverzWorld' target='_blank' rel='noopener noreferrer'>
                        <img src={l_tree} alt="" />
                    </a>
                    <h3>Driverz Link Tree</h3>
                    <p>Your essential hub connecting you to all things Driverz. One click, endless possibilities.</p>
                </div>
                <div className="column-tile">
                    <img src={exclusive} alt="" style={{ cursor: "pointer" }} onClick={() => initAllCollections()} />
                    <h3>Driverz Account Setup</h3>
                    <p>Interested in joining the Driverz community? First Pit Stop! By clicking this button, you will enable the option to purchase and transfer all Driverz digital collectibles, including our VROOM token. Complete this step before making any purchases on our website.</p>
                </div>
                <div className='column-tile tile2'>
                    <a href="https://web3wear.com/main/driverz" target='_blank' rel='noopener noreferrer'>
                        <img src={merch} alt="" />
                    </a>
                    <h3>Driverz Inc. Merchandise</h3>
                    <p>Show off your passion for speed with Driverz merchandise, an exclusive collection of clothing, accessories, and unique 1/1 pieces featuring your favorite collectibles</p>
                </div>
                <div className='column-tile tile3'>
                    <a href="https://www.flowscorenft.com/#/DRVZ" target='_blank' rel='noopener noreferrer'>
                        <img src={flwscr} alt="" />
                    </a>
                    <h3>Driverz on FlowScore</h3>
                    <p>Unleash the full potential of your Driverz collection with FlowScore, our official rarity tool, designed to empower owners and prospective buyers with valuable insights into their digital assets</p>
                </div>
                <div className='column-tile tile4'>
                    <a href='https://fantasy.formula1.com/' target='_blank' rel='noopener noreferrer' >
                        <img src={ftsy} alt=" "  />
                    </a>
                    <h3>Fantasy F1 Leagues</h3>
                    <p>Join the Driverz Fantasy Leagues, where your Driverz ownership unlocks thrilling Formula 1 races and potential cash prizes. Own more, win more!</p>
                </div>
                <div className='column-tile tile5'>
                    <a href='https://calendar.google.com/calendar/embed?src=1etkqcmdtrovofu05eeic60efs%40group.calendar.google.com&ctz=America%2FNew_York' target='_blank' rel='noopener noreferrer'>
                        <img src={g_cal} alt="" />
                    </a>
                    <h3>Driverz Event Calendar</h3>
                    <p>Stay in the fast lane with our Google Calendar, your go-to source for all upcoming Driverz community events. Be part of the action, don't miss a beat!</p>
                </div>
                <div className='column-tile tile6'>
                    <a href='https://www.piggobank.io/driverz' target='_blank' rel='noopener noreferrer'>
                        <img src={dntn} alt="" />
                    </a>
                    <h3>Driverz Charity Initiative</h3>
                    <p>Join our mission for positive change through the Driverz Charity Bank. Donate cash or collectibles and help us steer valuable contributions to charities chosen by our community.</p>
                </div>
                <div className='column-tile tile7'>
                    <a href='https://www.playback.tv/driverz' target='_blank' rel='noopener noreferrer' >
                        <img src={plbk} alt="" />
                    </a>
                    <h3>Live F1 Race Streams</h3>
                    <p>Join us for the thrill of every Formula One race in the 2023 season. Engage in lively discussions, share the excitement with the Driverz team and special guests, and make every race day unforgettable.</p>
                </div> {/*}
                <div className='column-tile tile8'>
                    <a href='https://ongaia.com/setup-driverz' target='_blank' rel='noopener noreferrer' >
                        <img src={initGen} alt="" />
                    </a>
                    <h3>Initialize Genesis Collection</h3>
                    <p>Start your Driverz journey by initializing your Genesis collection on Gaia. This simple step primes your account to receive unique Driverz airdrops and exclusive rewards. Let's get started today and gear up for the exciting rewards coming your way!</p>
             
                    
    </div> */}
                <div className='column-tile tile9'>
                    <a href='https://docs.google.com/spreadsheets/d/1fIDETfQr1W81i96jGdimIWSzg3_-AF7LLC6iIT2ZaTo/edit?usp=sharing' target='_blank' rel='noopener noreferrer' >
                        <img src={vroom} alt="" />
                    </a>
                    <h3>VROOM Calculator</h3>
                    <p>All Driverz collectibles are eligible to earn VROOM token. See how much token the inventory in your garage will earn. Exchange your VROOM token for digital collectibles, IRL racing & karting experiences, & more!</p>
             
                    
                </div>

                {/*}
                <div className="column-tile">
                    <img src={exclusive} alt="" style={{ cursor: "pointer" }} onClick={() => initStarterPack()} />
                    <h3>Initialize Driverz Starter Pack</h3>
                    <p>Click on the icon to set up your account to receive a Driverz Starter Pack.</p>
                </div>*/}
          </div>


        </div>
            
       
        
    )
}