export const initAll = `

//Testnet

//import FungibleToken from 0x9a0766d93b6608b7
//import DriverzNFT from 0x39bea87bca27e210
//import VroomToken from 0xb23640d151fd611d
//import CarClub from 0xb23640d151fd611d
//import DriverzExclusive  from 0xb23640d151fd611d
//import MetadataViews from 0x631e88ae7f1d7c20
//import NonFungibleToken from 0x631e88ae7f1d7c20

//Mainnet

import FungibleToken from 0xf233dcee88fe0abe
import DriverzNFT from 0xa039bd7d55a96c0c
import VroomToken from 0xf887ece39166906e
import CarClub from 0xf887ece39166906e
import DriverzExclusive from 0xf887ece39166906e
import MetadataViews from 0x1d7e57aa55817448
import NonFungibleToken from 0x1d7e57aa55817448

transaction() {

let address: Address

    prepare(account: AuthAccount){

        self.address = account.address

        let genCap = account.getCapability<&{NonFungibleToken.CollectionPublic, DriverzNFT.CollectionPublic}>(DriverzNFT.CollectionPublicPath)

        if !genCap.check(){
            account.save(<- DriverzNFT.createEmptyCollection(), to: DriverzNFT.CollectionStoragePath)
            account.link<&DriverzNFT.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, DriverzNFT.CollectionPublic, MetadataViews.ResolverCollection}>(DriverzNFT.CollectionPublicPath, target: DriverzNFT.CollectionStoragePath)
        }

        let excCap = account.getCapability<&{NonFungibleToken.CollectionPublic, DriverzExclusive.CollectionPublic}>(DriverzExclusive.CollectionPublicPath)

        if !excCap.check(){
            account.save(<- DriverzExclusive.createEmptyCollection(), to: DriverzExclusive.CollectionStoragePath)
            account.link<&DriverzExclusive.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, DriverzExclusive.CollectionPublic, MetadataViews.ResolverCollection}>(DriverzExclusive.CollectionPublicPath, target: DriverzExclusive.CollectionStoragePath)
        }

        let carCap = account.getCapability<&{NonFungibleToken.CollectionPublic, CarClub.CollectionPublic}>(CarClub.CollectionPublicPath)

        if !carCap.check(){
            account.save(<- CarClub.createEmptyCollection(), to: CarClub.CollectionStoragePath)
            account.link<&CarClub.Collection{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, CarClub.CollectionPublic, MetadataViews.ResolverCollection}>(CarClub.CollectionPublicPath, target: CarClub.CollectionStoragePath)
        }

        if account.borrow<&VroomToken.Vault>(from: VroomToken.VaultStoragePath) == nil {
            let vault <- VroomToken.createEmptyVault()
            account.save<@VroomToken.Vault>(<-vault, to: VroomToken.VaultStoragePath)
        }

        let vroomTokenCap = account.getCapability<&VroomToken.Vault{FungibleToken.Receiver}>(VroomToken.VaultReceiverPath)
        if(!vroomTokenCap.check()) {
            account.unlink(VroomToken.VaultReceiverPath)
            // Create a public Receiver capability to the Vault
            account.link<&VroomToken.Vault{FungibleToken.Receiver, FungibleToken.Balance}>(VroomToken.VaultReceiverPath, target: VroomToken.VaultStoragePath)
        }

        let vroomTokenCapBalance = account.getCapability<&VroomToken.Vault{FungibleToken.Balance}>(VroomToken.VaultBalancePath)
        if(!vroomTokenCapBalance.check()) {
            account.unlink(VroomToken.VaultBalancePath)
            // Create a public Receiver capability to the Vault
            account.link<&VroomToken.Vault{FungibleToken.Balance}>(VroomToken.VaultBalancePath, target: VroomToken.VaultStoragePath)
        }
    }
}
`
