import { useEffect, useState } from 'react';
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import './Garage_New.css'
import bckgd from '../../assets/Images/Garage.jpg'

import { db } from '../../firebaseNew'
import { getAllNFTs } from '../../Cadence/marketplace/getAllNFTs';
import { checkVroomBalance } from '../../Cadence/Scripts/checkVroomBalance';
import NFTItem from '../../Cadence/NFTItem';
// import FirebaseService from '../../services/FirebaseServiceNew'
import { collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore"
import { getListingInsDetails } from "../../Cadence/insurance/getListingInsuranceDetails";
import { purchaseInsurance } from "../../Cadence/insurance/purchaseInsurance"; // done
import { transferVroom } from "../../Cadence/Transactions/transferVroom";
import { transferCarClub } from "../../Cadence/Transactions/transferCarClub";

export default function Collection() {
  const [user, setUser] = useState({ loggedIn: null });  
  const [nft, setNFT] = useState([]);
  const [bal, setBal] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [formKey, setFormKey] = useState(null);
  const [stakingInitialized, setStakingInitialized] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [stakedNfts, setStakedNfts] = useState([]);
  const [userDocId, setUserDocId] = useState(null);
  const [totalEarned, setTotalEarned] = useState(null);
  const usersCollectionRef = collection(db, "Staking-Users")
  const [receiver, setReceiver] = useState([]);
  const [amount, setAmount] = useState("");
  const [recipientAd, setRecipientAd] = useState([]);
  const [CCvalue, setCCValue] = useState("");
  const [receiverValid, setReceiverValid] = useState(false);
  const [amountValid, setAmountValid] = useState(false);
  const [nullListings, setNullListings] = useState([]);
  const [CCRecipientValid, setCCRecipientValid] = useState(false);
  const [CCIdValid, setCCIdValid] = useState(false);
  const [ccTransferError, setCCTransferError] = useState("");
  const [carClubNFTIds, setCarClubNFTIds] = useState([]);
  const [selectedCarClubId, setSelectedCarClubId] = useState('');


  var content = '';

  // Handler to update state when an option is selected




  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
    setUserAddress(user.addr);
    console.log(userAddress);
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getDocs(usersCollectionRef);
      console.log(userData);
    };

    getUserData();
  }, []);



  const fetchStakedNfts = async () => {
    try {
      const userAddress = user.addr;
      const querySnapshot = await getDocs(usersCollectionRef);
      const matchingDoc = querySnapshot.docs.find(
        (doc) => doc.data()["Wallet Address"] === userAddress
      );
  
      if (matchingDoc) {
        console.log("Matching document found with ID:", matchingDoc.id);
        setUserDocId(matchingDoc.id);
  
        const userDocRef = doc(db, 'Staking-Users', matchingDoc.id);
        const stakedNftsCollectionRef = collection(userDocRef, 'Staked-NFTs');
    
        const querySnapshotNfts = await getDocs(stakedNftsCollectionRef);
    
        const stakedNftsData = querySnapshotNfts.docs.map(doc => ({
          nftId: doc.data().nftId,
          nftType: doc.data().nftType
        }));
    
        console.log('Fetched Staked Nfts:', stakedNftsData);
  
        setStakedNfts(stakedNftsData);
        const totEarned = calculateTotalEarnings(stakedNftsData);
        setTotalEarned(totEarned);
      } else {
        console.log("No document found with the user's wallet address.");
      }
    } catch (error) {
      console.error('Error fetching staked NFT IDs: ', error);
    }
  };

  useEffect(() => {
    if (user.loggedIn) {
      fetchStakedNfts();

    }
  }, [user.loggedIn]);

  function calculateTotalEarnings(stakedNftsData) {
    let totalEarned = 0;
    let carClubCount = 0;
  
    stakedNftsData.forEach((stakedNftDoc) => {
      const nftId = parseInt(stakedNftDoc.nftId);
      console.log("Nft ID", stakedNftDoc.nftId)
      let multiplier = 1;
  
      if (stakedNftDoc.nftType === 'Car Club') {
        carClubCount++;
        if (nftId < 4701) {
          multiplier = 1;
        } else if (nftId >= 4701 && nftId <= 4985) {
          multiplier = 2;
        } else if (nftId >= 4986 && nftId <= 5000) {
          multiplier = 3;
        } else if (nftId >= 5001 && nftId <= 5525) {
          multiplier = 2;
        } else if (nftId >= 5526 && nftId <= 5555) {
          multiplier = 5;
        }
      } else if (stakedNftDoc.nftType === 'Genesis') {
        multiplier = 0.25;
      } else if (stakedNftDoc.nftType === 'Car') {
        multiplier = 0.77;
      } else if (stakedNftDoc.nftType === 'Wheel') {
        multiplier = 0.3;
      } else if (stakedNftDoc.nftType === 'Helmet') {
        multiplier = 0.2;
      } else if (stakedNftDoc.nftType === 'Tires') {
        multiplier = 0.15;
      }
  
      const earnedPerNFT = multiplier * 2.5; // Earned per NFT per ten minutes
      const totalEarnedPerNFT = earnedPerNFT;
  
      totalEarned += totalEarnedPerNFT;
    });
  
    if (carClubCount >= 7 && carClubCount < 15) {
      totalEarned *= 1.5;
    } else if (carClubCount >= 15) {
      totalEarned *= 2;
    }
  
    totalEarned = Number(totalEarned.toFixed(7));
  
    return totalEarned;
  }




  const checkUserDocument = async () => {
    if (!user.loggedIn) {
      console.log("User is not logged in.");
      return;
    }

    const userAddress = user.addr;

    try {
      const querySnapshot = await getDocs(usersCollectionRef);
      const matchingDoc = querySnapshot.docs.find(
        (doc) => doc.data()["Wallet Address"] === userAddress
      );

      if (matchingDoc) {
        console.log("Matching document found with ID:", matchingDoc.id);
        setStakingInitialized(true);
        setUserDocId(matchingDoc.id);
        fetchStakedNfts();
      } else {
        console.log("No document found with user's wallet address.");
      }
    } catch (error) {
      console.error("Error checking document: ", error);
    }
  };

  useEffect(() => {
    getNFTs();
    getVroomBalance();
    checkUserDocument();
  }, [user]);

  const getNFTs = async () => {
    if (user.loggedIn === null) return;
    const res = await fcl.query({
      cadence: getAllNFTs,
      args: (arg, t) => [arg(user?.addr, t.Address)],
    });

    console.log('NFT - ', res);
    setNFT(res);

    // Filtering Car Club NFTs and extracting their IDs
    const carClubIds = res.filter(nft => nft.nftType === "Car Club").map(nft => nft.nftId);
    
    // Updating state with Car Club NFT IDs
    setCarClubNFTIds(carClubIds);
  };

  const initializeStaking = async () => {

    console.log("You are here!");

    if(!user.loggedIn) {
      console.log("User is not logged in");
      return;
    }
    
    const userAddress = user.addr;

    try {
      console.log("Adding document to Firestore...");
      await addDoc(usersCollectionRef, {"Wallet Address": userAddress});
      console.log("Document added successfully!");
      toast.success("Staking Initialized Successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Failed to Initialze Staking");
    };
  };
/*
    if(formKey === null){
      FirebaseService.create(data)
      .then(() => {
        toast.success("Successfully submitted!");
      }).catch(e => {
        toast.error(e);
      });
    }else{
      FirebaseService.update(formKey, data)
      .then(() => {
        toast.success("Successfully updated!");
      }).catch(e => {
        toast.error(e);
      });
    }*/



  const getVroomBalance = async () => {
    if (user.loggedIn === null) return;
    const bal = await fcl.query({
      cadence: checkVroomBalance,
      args: (arg, t) => [arg(user?.addr, t.Address)]
    });
    setBal(bal);
  };




  // TRANSFER VROOM TOKEN LOGIC 

      // Validate Flow address pattern
      useEffect(() => {
        const flowAddressPattern = /^0x[a-fA-F0-9]{16}$/;
        setReceiverValid(flowAddressPattern.test(receiver));
      }, [receiver]);
  
      // Validate amount and append .0 if needed
      useEffect(() => {
        const parts = amount.split(".");
        if (!isNaN(Number(amount)) && Number(amount) > 0) {
            setAmountValid(true);
            if (parts[1] && parts[1].length > 7) {
                setAmountValid(false);
            }
        } else {
            setAmountValid(false);
        }
    }, [amount]);
    

  
     const handleAmountChange = (e) => {
      const value = e.target.value;
      const parts = value.split(".");
   
      if (parts[1] && parts[1].length > 7) {
        return; // Prevent adding more than 7 digits after decimal
      }
   
      setAmount(value);
   };

   const handleCCRecipientAddressChange = (e) => {
    const address = e.target.value;
    const flowAddressPattern = /^0x[a-fA-F0-9]{16}$/;
    setCCRecipientValid(flowAddressPattern.test(address));
    setRecipientAd(address);
    console.log("Address Valid", CCRecipientValid);
  };

  const handleCarClubIdSelection = (event) => {
    setSelectedCarClubId(event.target.value);
  };
  
  const handleCCIDChange = (e) => {
    const id = e.target.value;
    const numId = Number(id); // Convert input to a number for validation
    if (!isNaN(numId) && numId >= 1 && numId <= 9999 && Number.isInteger(numId)) {
      setCCValue(numId); // Update state if validation passes
      setCCIdValid(true);
      console.log("ID Valid", CCIdValid);
    } else {
      setCCIdValid(false); // Invalidate the input if checks fail
      console.lof("ID Valid", CCIdValid);
    }
  };


  const getNullListings = async () => {
    try {
      const listings = await fcl.query({
        cadence: getListingInsDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });

      console.log(listings);
      setNullListings(listings);

      const listingIDsWithPrice49 = listings
        .filter((listing) => listing.price === "0.10000000")
        .map((listing) => listing.listingID);

      console.log("Listing IDs with price null:", listingIDsWithPrice49);

      setNullListings(listingIDsWithPrice49);

    } catch (error) {};
};


  useEffect(() => {
    getNullListings();
   // getPackListings();
}, [])



  const handleTransfer = async () => {
    try {
        const TXid = await fcl.mutate({
            cadence: transferVroom,
            args: (arg, t) => [
              arg(amount, t.UFix64),
              arg(receiver, t.Address)
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 999,
        });
        return TXid;
    } catch(error) {
        console.error("Error in handleTransfer:", error);
    }
};

const handleCCTransfer = async () => {
  try {
    const transactionId = await fcl.mutate({
      cadence: transferCarClub,
      args: (arg, t) => [
        arg(recipientAd, t.Address),
        arg("["+selectedCarClubId + "]", t.Array(t.UInt64)),
      ],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 100,
    });
    console.log("Transaction ID:", transactionId);
  } catch (error) {
    console.error("Transaction failed:", error);
  }
};



  content = 
    <div className='garage-item-container'>
      {nft.map((data, index) => {
        return (
          <div key={index} className='garage-item'>
            <NFTItem data = {data} userAddress={user.addr} isStaked={stakedNfts.includes(data.nftId.toString())}></NFTItem>
          </div>
        )
        
      })}    
    </div>



  return(
    <div className="garage-coming-pad mt-5 mx-5">
      <img className="garage-full-width-image" src={bckgd} alt="" />


      {user.loggedIn ?
      <>
        {!stakingInitialized && (
        <div className='staking-button-row'>
          <button className='mint-button' onClick={initializeStaking}>Initialize Staking</button>
        </div>
        )}
        {bal !== null ? (
          
        <div>
          <div className="vroom-balance-row">
            <div>
              <h2>Statistics</h2>
            </div>
            <div className="info-section">
              <div className="info-column">
                <div className="info-title">Vroom Token Balance</div>
                <div className="info-value">{Number.parseFloat(bal).toFixed(2)} $VROOM</div>
              </div>
              <div className="info-column">
                <div className="info-title">Earning Rate Per Day</div>
                <div className="info-value">{totalEarned}</div>
              </div>
              <div className="info-column">
                <div className="info-title">Staked Collectibles</div>
                <div className="info-value">{stakedNfts.length}</div>
              </div>
            </div>
          
            <div className="section-divider-2"></div>
            <div className='garage-main'>
                <h2>Transfer Assets</h2>
            </div>

            <div className='vroom-transfer-row'>


              <div className='vroom-transfer-box'>
                <div className='vroom-input-row'><p className='transfer-box-header'>Transfer $VROOM</p></div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={receiver}
                        onChange={e => setReceiver(e.target.value)}
                        placeholder="Receiver Address"
                    />
                    {!receiverValid && <span className='invalid-feedback'>Invalid Flow address</span>}
                </div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder="Amount"
                    />
                    {!amountValid && <span className='invalid-feedback'>Invalid amount</span>}
                </div>
                <div className='vroom-input-row'>
                  <p style={{color: "red", fontSize: "0.9rem"}}>Please append '.0' to whole number values when entering the amount.</p>
                    <button onClick={handleTransfer} disabled={!receiverValid || !amountValid}>
                        Transfer $VROOM
                    </button>
                </div>
              </div>
              {/*<div className='vroom-transfer-box'>
                <div className='vroom-input-row'><p className='transfer-box-header'>Transfer Car Club</p></div>
                <div className='vroom-input-row'>
                    <input
                        type="text"
                        value={recipientAd}
                        onChange={handleCCRecipientAddressChange} 
                        placeholder="Receiver Address"
                    />
                    {!CCRecipientValid && <span className='invalid-feedback'>Invalid Flow address</span>}
                </div>
                <div className='vroom-input-row'>
                  {/* Dropdown for selecting Car Club ID 
                  <select value={selectedCarClubId} onChange={handleCarClubIdSelection}>
                    <option value="">Select a Car Club ID</option>
                    {carClubNFTIds.map((id) => (
                      <option key={id} value={id}>{id}</option>
                    ))}
                  </select>
                  {ccTransferError && <span className='invalid-feedback'>{ccTransferError}</span>}
                  </div>

                  <div className='vroom-input-row'>
                  <button onClick={handleCCTransfer} disabled={ ccTransferError}>
                    Transfer Car Club
                  </button>
                </div>

              </div>*/}
            </div>
            <div className="section-divider-2"></div>
            <div className='garage-main'>
              <h2>Collectibles</h2>
            </div>
          </div>
        </div>
        ) : null}
        <div className="mb-5">
            {content}
        </div>

        
        </>      
      :
      <p className='garage-sign-in-text text-center'>
        Hit the pit stop! Sign in to fuel up and view your high-octane Driverz collectibles!
      </p>
      }

      <ToastContainer />
    </div>    
  )
}