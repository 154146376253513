export const createBidVroomTresor = `
import FungibleToken from 0xf233dcee88fe0abe
import VroomToken from 0xf887ece39166906e
import VroomTokenRepository from 0xf887ece39166906e
import HouseMetadata from 0x66b60643244a7738

transaction(price: UFix64, amount: UFix64, auctionType: String, timeStamp: String, txID: String) {

    let signer: AuthAccount
    let vroomTokenVaultRef: &VroomToken.Vault{FungibleToken.Provider}

    prepare(signer: AuthAccount) {
        self.signer = signer

        // Borrow a reference to the signer's VroomToken vault
        self.vroomTokenVaultRef = signer.borrow<&VroomToken.Vault{FungibleToken.Provider}>(
            from: VroomToken.VaultStoragePath
        ) ?? panic("Could not borrow reference to the VroomToken vault")
        
        // Attempt to borrow a reference to the VroomTokenRepository's Repository
        var repositoryRef = signer.borrow<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryManager}>(
            from: VroomTokenRepository.RepositoryStoragePath
        )

        if repositoryRef == nil {
            // Create a new Repository
            let repository <- VroomTokenRepository.createRepository()
        
            // Save it to the signer's account
            signer.save(<-repository, to: VroomTokenRepository.RepositoryStoragePath)
        
            // Create a public link to the Repository
            signer.link<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryPublic}>(
                VroomTokenRepository.RepositoryPublicPath,
                target: VroomTokenRepository.RepositoryStoragePath
            )
        
            // Retry borrowing after creating the repository
            repositoryRef = signer.borrow<&VroomTokenRepository.Repository{VroomTokenRepository.RepositoryManager}>(
                from: VroomTokenRepository.RepositoryStoragePath
            ) ?? panic("Could not borrow reference to the newly created VroomTokenRepository")
        }
        
        // Since we've handled the nil possibility, we can now forcibly unwrap
        let tresorId = repositoryRef!.createTresor(
            signer: self.signer, 
            price: price, 
            amount: amount
        )

        // Add metadata about the bid
        HouseMetadata.addMetadata(
            userAddress: signer.address,
            auctionType: auctionType,
            currentBid: amount,
            timeStamp: timeStamp,
            txID: txID,
            tresorID: tresorId
        )
    }

    execute {
        // Execution logic (if any) can be added here
    }
}

`