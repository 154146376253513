import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet'
import * as fcl from "@onflow/fcl";
import { Card, Button, Alert, InputGroup, Form } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CarClubMintPage.css';
import bckgd from '../../assets/Images/Car_Club.jpg';
import carClub1 from '../../assets/Images/CarClub1.png';
import carClub2 from '../../assets/Images/CarClub2.png';
import cClub_rare_1 from '../../assets/Videos/5181.mp4';
import cClub_3 from '../../assets/Images/2151.png';
import cClub4 from '../../assets/Images/carClub4888.png';
import starterPack from '../../assets/PackVideos/Starter_Pack.mp4'
import pitcrewPack from '../../assets/PackVideos/Pit_Crew_Pack.mp4'
import toolboxBundle from '../../assets/PackVideos/Toolbox_Bundle_Pack.mp4'

import { checkCarClub } from "../../Cadence/carclub/checkCarClub"; //fixed imports
import { initCarClub } from "../../Cadence/carclub/initCarClub"; //done
import { multiPurchaseCarClub } from "../../Cadence/carclub/multiPurchaseCarClub"; //done
import { getListingId } from "../../Cadence/carclub/getListingId"; //done
import { unlinkAccount } from "../../Cadence/carclub/unlinkAccount"; //done
//import { checkGenesis } from "../Cadence/checkGenesis";
import { checkOwnGenesis } from "../../Cadence/carclub/checkOwnGenesis"; //done
import { getListingInsurance } from "../../Cadence/insurance/getListingInsurance"; //done
import { purchaseInsurance } from "../../Cadence/insurance/purchaseInsurance"; // done
import { checkOwnInsurance } from "../../Cadence/insurance/checkOwnInsurance"; //done
import { purchaseCar } from "../../Cadence/marketplace/purchaseCar";
import { purchaseNFTDapper } from "../../Cadence/marketplace/purchaseNFTDapper";
import { purchaseWheel } from "../../Cadence/marketplace/purchaseWheel";
import { getPackListings } from "../../Cadence/getPackListings"
import { getListingDetails } from "../../Cadence/Scripts/getListingDetails"
import { purchaseDriverzAirdrop } from "../../Cadence/marketplace/Purchase-Tire-transaction";
import { getListingInsDetails } from "../../Cadence/insurance/getListingInsuranceDetails";
import { initAll } from '../../Cadence/Transactions/initAll';


export default function Mint() {
  const [user, setUser] = useState({ loggedIn: null });
  const [isCarClubInit, setIsCarClubInit] = useState(false);
  const [listedNFT, setListedNFT] = useState([]);
  const [mintCount, setMintCount] = useState(1);
  const [listedInsurance, setListedInsurance] = useState([]);
  const [ownInsurance, setOwnInsurance] = useState(false);
  const [ownGenesis, setOwnGenesis] = useState(false);
  const [soldOut, setSoldOut] = useState(false);
  const [SPListings, setSPListings] = useState([]);
  const [PCListings, setPCListings] = useState([]);
  const [TBListings, setTBListings] = useState([]);

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    // checkCarClubInitialized();
    getListingIdFunc();
    getListingInsuranceFunc();
    checkOwnInsuranceFunc();
    checkOwnGenesisFunc();
  }, [user]);

  const getListingIdFunc = async () => {
    const res = await fcl.query({
      cadence: getListingId,
      args: (arg, t) => [arg("0xf887ece39166906e", t.Address)],
      limit: 9999
    });
    setListedNFT(res);
    // console.log(res.length);
  }
  const getListingInsuranceFunc = async () => {
    const res = await fcl.query({
      cadence: getListingInsurance,
      args: (arg, t) => [arg("0xf887ece39166906e", t.Address)],
    });

    const listings = res ?? [];

    const remainingListings = listings.length - 299;
    const limitedListings = remainingListings > 0 ? listings.slice(0, remainingListings) : [];

    setListedInsurance(limitedListings);
    console.log(limitedListings.length, limitedListings);
    setSoldOut(remainingListings <= 0);
  }

  const checkCarClubInitialized = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    if (currentUser.addr != null) {
      const res = await fcl.query({
        cadence: checkCarClub,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });

      // console.log('init car club - ', res);
      setIsCarClubInit(res);
    }
  };
  const checkOwnInsuranceFunc = async () => {
    const currentUser = await fcl.currentUser().snapshot();
    
    if (currentUser.addr != null) {
      const res = await fcl.query({
        cadence: checkOwnInsurance,
        args: (arg, t) => [arg(currentUser?.addr, t.Address)],
      });

      setOwnInsurance(res);
      console.log('own insurance - ', res);
    }
  };
  const checkOwnGenesisFunc = async () => {
    {/*const currentUser = await fcl.currentUser().snapshot();
    
    if (currentUser.addr != null) {
      const isGenesis = await fcl.query({
        cadence: checkOwnGenesis,
        args: (arg, t) => [
          arg(currentUser?.addr, t.Address)
        ],
      });*/}
      
      // console.log("own genesis - ", isGenesis);
      setOwnGenesis(true);
      console.log("Is Genesis:", ownGenesis);
    }
    
  

  const initCarClubClick = async () => {
    try {
      const txid = await fcl.mutate({
        cadence: initCarClub,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully initialized for Car Club!");
      setIsCarClubInit(true);
    } catch (error) {
      // console.log("err", error);
      toast.error(error);
    }
  };

  const initAllClick = async () => {
    try {
      const init = await fcl.mutate ({
        cadence: initAll,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      toast.success("Successfully initialized for Car Club!");
      setIsCarClubInit(true);

    } catch (error) {

    };
  };

  // console.log("listed NFT - ", listedNFT);

  const mintCarClubClick = async () => {

    var listedID = [];
    for (let i = 0; i < mintCount; i++) {
      listedID.push(listedNFT[i]);
    }

    console.log("Car Club Mint Listing: ", listedID);

    if (ownGenesis) {
      var price = parseFloat(mintCount * 37);

      try {
        const txid = await fcl.mutate({
          cadence: multiPurchaseCarClub,
          args: (arg, t) => [
            arg('0x6817fd8ae667556e', t.Address),
            arg('0xf887ece39166906e', t.Address),
            arg(listedID, t.Array(t.UInt64)),
            arg(price + ".0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64)//
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });

        console.log(txid);
        toast.success("Successfully minted Car Club!");
      } catch (error) {
        console.log("err", error);
        console.log("error.message", error.message);

        /*fetch("/log", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ error: error.message }),
          })
            .then((res) => {
              if (res.ok) {
                console.log("Error logged successfully");
              } else {
                console.log("Error logging failed:", res.status);
              }
            })
            .catch((fetchError) => {
              console.log("Error logging fetch error:", fetchError);
            });*/
        
          toast.error(error);
        }
        
    }
    
    else {
      var price = parseFloat(mintCount * 57);

      try {
        const txid = await fcl.mutate({
          cadence: multiPurchaseCarClub,
          args: (arg, t) => [
            arg('0x6817fd8ae667556e', t.Address),
            arg('0xf887ece39166906e', t.Address),
            arg(listedID, t.Array(t.UInt64)),
            arg(price + ".0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
        });

        console.log(txid);
        toast.success("Successfully minted Car Club!");
      } catch (error) {
        console.log("err", error);
        console.log("error.message", error.message);

        fetch("/log", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ error: error.message }),
          })
            .then((res) => {
              if (res.ok) {
                console.log("Error logged successfully");
              } else {
                console.log("Error logging failed:", res.status);
              }
            })
            .catch((fetchError) => {
              console.log("Error logging fetch error:", fetchError);
            });
        
          toast.error(error);
        }
      
    }

  }

  const getSPListings = async () => {
    try {
      const listings = await fcl.query({
        cadence: getListingInsDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });

      console.log(listings);
      setSPListings(listings);

      const listingIDsWithPrice49 = listings
        .filter((listing) => listing.price === "49.00000000")
        .map((listing) => listing.listingID);

      console.log("Listing IDs with price 49:", listingIDsWithPrice49);

      setSPListings(listingIDsWithPrice49);

    } catch (error) {};
  };




  const listedIDs = [
    '1136032435', '1136032436', '1136032437', '1136032438', '1136032439', '1136032440', '1136032441', '1136032442', '1136032443', '1136032444', '1136032445', '1136032446', '1136032447', '1136032448', '1136032449', '1136032450', '1136032451', '1136032452', '1136032453', '1136032454', '1136032455', '1136032456', '1136032457', '1136032458', '1136032459', '1136033583', '1136033584', '1136033585', '1136033586', '1136033587', '1136033588', '1136033589', '1136033590', '1136033591', '1136033592', '1136033593', '1136033594', '1136033595', '1136033596', '1136033597', '1136033598', '1136033599', '1136033600', '1136033601', '1136033602', '1136033603', '1136033604', '1136035264', '1136035265', '1136035266', '1136035267', '1136035268', '1136035269', '1136035270', '1136035271', '1136035272', '1136035273', '1136035274', '1136035275', '1136035276', '1136035277', '1136035278', '1136035279', '1136035280', '1136035281', '1136035282', '1136035283', '1136035284', '1136035285', '1136035768', '1136035769', '1136035770', '1136035771', '1136035772', '1136035773', '1136035774', '1136035775', '1136035776', '1136035777', '1136035778', '1136035779', '1136035780', '1136035781', '1136035782', '1136035783', '1136035784', '1136035785', '1136035786', '1136036741', '1136036742', '1136036743', '1136036744', '1136036745', '1136036746', '1136036747', '1136036748', '1136036749'
  ];  

  /*
  const filterMatchingListings = () => {


    const matchingListings = SPListings.filter((item) => {
      const listingID = parseInt(item, 10).toString();
      const isMatch = listedIDs.includes(listingID);
      console.log(`ListingID: ${listingID}, isMatch: ${isMatch}`);
      return isMatch;
    });

    console.log("Matching Listings: ", matchingListings);

    const matchingListing = matchingListings[0];

    console.log("Matching Listing: ", matchingListing);

    return matchingListing;
  
  };
*/

  const [nullListings, setNullListings] = useState([]);

  const getNullListings = async () => {
    try {
      const listings = await fcl.query({
        cadence: getListingInsDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });

      console.log(listings);
      setNullListings(listings);

      const listingIDsWithPrice49 = listings
        .filter((listing) => listing.price === "37.00000000")
        .map((listing) => listing.listingID);

      console.log("Listing IDs with price null:", listingIDsWithPrice49);

      setNullListings(listingIDsWithPrice49);

    } catch (error) {};
  };

  useEffect(() => {
    getNullListings();
  }, [])

  const handleNullPurchase = async () => {
    await getNullListings();
    await purchaseNull();
  };

  const purchaseNull = async (/*matchingListing*/) => {

    try {
        // const listingID = matchingListing?.listingID?.toString();     
        // console.log(listingID);

        // const ID = findListingID();
        const listingID = nullListings[0];        
        console.log("TX ID Input: ", typeof listingID, listingID)

      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg("0xf887ece39166906e", t.Address),
          arg(listingID, t.UInt64),
          arg('37.0', t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      } catch(error) {}
  };

  useEffect(() => {
    getSPListings();
  }, [])

  const findListingID = () => {
    console.log('FOR LOOP CHECK')
    for (let i = 0; i < SPListings.length; i++) {
      console.log('i', SPListings[i].price, i);
      if (SPListings[i].price === '70.00000000') {
        const listingID = SPListings[i].listingID;
        console.log('Matching Listing ID: ', listingID);
        console.log('INFO>> ', i, SPListings[i].price )    
        return listingID; 
           
      }
    }
    return null;
  };

  const handlePurchaseClickSP = async () => {
    await getSPListings();
    await purchaseStarterPack();
  }; 

  const purchaseStarterPack = async (/*matchingListing*/) => {

    try {
        // const listingID = matchingListing?.listingID?.toString();     
        // console.log(listingID);

        const ID = findListingID();
        const listingID = SPListings[0];        
        console.log("TX ID Input: ", typeof listingID, listingID)

      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg("0xf887ece39166906e", t.Address),
          arg(listingID, t.UInt64),
          arg('49.0', t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      } catch(error) {}
  };
  {/* 
  const mintInsuranceClick = async () => {

    try {
      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg('0xf887ece39166906e', t.Address),
          arg(listedInsurance[0], t.UInt64),
          arg('10.0', t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      checkOwnInsuranceFunc();
      toast.success("Successfully minted Driverz Insurance!");
    } catch (error) {
      console.log("err", error);
      toast.error(error);
    }
 
  }

  */}

  const listedIDsPitCrew = [ '1137070517', '1137070518', '1137070519', '1137070520', '1137070521', '1137070522', '1137070523', '1137070524', '1137070525', '1137070526', '1137070527', '1137070528', '1137070529', '1137072058', '1137072059', '1137072060', '1137072061', '1137072062', '1137072063', '1137072064', '1137072065', '1137072066', '1137072067', '1137072068', '1137072069', '1137072070', '1137072071', '1137072072', '1137072073', '1137072074', '1137072075', '1137072076', '1137072077', '1137072078', '1137073672', '1137073673', '1137073674', '1137073675', '1137073676', '1137073677', '1137073678', '1137073679', '1137073680', '1137073681', '1137073682', '1137073683', '1137073684', '1137073685', '1137073686', '1137073687', '1137073688', '1137073689', '1137073690', '1137073691', '1137073692', '1137075438', '1137075439', '1137075440', '1137075441', '1137075442', '1137075443', '1137075444', '1137075445', '1137075446', '1137075447', '1137075448', '1137075449', '1137075450', '1137075451', '1137075452', '1137075453', '1137075454', '1137075455', '1137075456', '1137075457', '1137075458', '1137077557', '1137077558', '1137077559', '1137077560', '1137077561', '1137077562', '1137077563', '1137077564', '1137077565', '1137077566', '1137077567', '1137077568', '1137077569', '1137077570'];

  let currentIndexPitCrew = 0;

  const getPCListings = async () => {
    try {
      const listingsPC = await fcl.query({
        cadence: getListingInsDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });

      const listingIDsWithPrice77 = listingsPC
        .filter((listingPC) => listingPC.price === "77.00000000")
        .map((listingPC) => listingPC.listingID);

      console.log("Listing IDs with price 77:", listingIDsWithPrice77);

      setPCListings(listingIDsWithPrice77)

    } catch (error) {};


  };

  const purchasePitCrewPack = async () => {
    try {
      await getPCListings();
      const listingID = PCListings[0];
      console.log("TX ID Input: ", typeof listingID, listingID)

      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg("0xf887ece39166906e", t.Address),
          arg(listingID, t.UInt64),
          arg("77.0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      currentIndexPitCrew = (currentIndexPitCrew + 1) % listedIDsPitCrew.length;
    } catch(error) {}
  }




  const listedIDsToolbox = ['1137297578', '1137297579', '1137297580', '1137297581', '1137297582', '1137297583', '1137297584', '1137297585', '1137297586', '1137297587', '1137297588', '1137297589', '1137297590', '1137297591', '1137297592', '1137297593', '1137297594', '1137300327', '1137300328', '1137300329', '1137300330', '1137300331', '1137300332', '1137300333', '1137300334', '1137300335', '1137300336', '1137300337', '1137300338', '1137300339', '1137300340', '1137300341', '1137300342', '1137300343', '1137300344', '1137300345', '1137300346', '1137300347', '1137301641', '1137301642', '1137301643', '1137301644', '1137301645', '1137301646', '1137301647', '1137301648', '1137301649', '1137301650', '1137301651', '1137301652', '1137301653', '1137301654', '1137301655', '1137301656', '1137301657', '1137301658', '1137301659', '1137301660', '1137301661', '1137304556', '1137304557', '1137304558', '1137304559', '1137304560', '1137304561', '1137304562', '1137304563', '1137304564', '1137304565', '1137304566', '1137304567', '1137304568', '1137304569', '1137304570', '1137304571', '1137304572', '1137304573', '1137304574', '1137304575', '1137304576', '1137306249', '1137306250', '1137306251', '1137306252', '1137306253', '1137306254', '1137306255', '1137306256', '1137306257', '1137306258', '1137306259', '1137306260', '1137306261', '1137306262'];
  let currentIndexToolbox = 0;

  const getTBListings = async () => {
    try {
      const listings = await fcl.query({
        cadence: getListingInsDetails,
        args: (arg, t) => [
          arg("0xf887ece39166906e", t.Address)
        ],
      });


      const listingIDsWithPrice110 = listings
        .filter((listing) => listing.price === "110.00000000")
        .map((listing) => listing.listingID);

      console.log("Listing IDs with price 110:", listingIDsWithPrice110);

      setTBListings(listingIDsWithPrice110);

    } catch (error) {};
  };

  const purchaseToolbox = async () => {
    try {
      
      await getTBListings();
      const listingID = TBListings[0];
      console.log("TX ID Input: ", typeof listingID, listingID)

      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg("0xf887ece39166906e", t.Address),
          arg(listingID, t.UInt64),
          arg("110.0", t.UFix64)//arg((price.toFixed(1)).toString(), t.UFix64) //arg(price + ".0", t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      currentIndexToolbox = (currentIndexToolbox + 1) % listedIDsToolbox.length;
    } catch(error) {}
  }

  const unLinkClick = async () => {
    try {
      const txid = await fcl.mutate({
        cadence: unlinkAccount,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully unlinked!");
    } catch (error) {
      console.log("err", error);
      toast.error(error);
    }
  }

  function plusClick() {
    if (mintCount < 15) {
      let count = mintCount;
      count++;
      setMintCount(count);
    }
  }
  function minusClick() {
    if (mintCount > 1) {
      let count = mintCount;
      count--;
      setMintCount(count);
    }
  }  

  const mintInsuranceClick = async () => {

    try {
      const txid = await fcl.mutate({
        cadence: purchaseInsurance,
        args: (arg, t) => [
          arg('0x6817fd8ae667556e', t.Address),
          arg('0xf887ece39166906e', t.Address),
          arg(listedInsurance[0], t.UInt64),
          arg('10.0', t.UFix64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      checkOwnInsuranceFunc();
      toast.success("Successfully minted Driverz Insurance!");
    } catch (error) {
      console.log("err", error);
      toast.error(error);
    }
 
  }

  return (
    <div className="container mt-5 car-club-page">

      <Helmet>
        <meta property="og:title" content="Driverz Car Club - Build Your Car Club Collection" />
        <meta property="og:description" content="Mint your exclusive Driverz Car Club collection and unlock exciting racing perks and VROOM token rewards. Join the Driverz community and start your racing journey today!" />
        <meta property="og:image" content={bckgd} />
      </Helmet>

      <div className="first-row">
        <h2>Buy Driverz Collectibles</h2>
        <h4>Unlock exclusive access to club perks <br />and start generating $VROOM!</h4>               
      </div>

      <div className="pack-row">

        <div className="pack-column">
        <video autoPlay loop muted playsInline>
            <source src={cClub_rare_1}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Car Club <br /> $37 </h3>
          <h4>Car Club Collectible</h4>
          <button onClick={handleNullPurchase} className='mint-button'>BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={starterPack}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Starter Pack <br />SOLD OUT</h3>
          <h4>2 Genesis <br /> 1 Car Club</h4>
          
          <button /*onClick={handlePurchaseClickSP}*/ className="mint-button">BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={pitcrewPack} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Pit Crew Pack <br />SOLD OUT</h3>
          <h4>4 Genesis</h4>
          
          <button /*onClick={purchasePitCrewPack}*/ className="mint-button">BUY</button>
        </div>

        <div className="pack-column">
          <video autoPlay loop muted playsInline>
            <source src={toolboxBundle} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <h3>Toolbox Bundle <br /> $110</h3>
          <h4>4 Car Club</h4>
          
          <button onClick={purchaseToolbox} className="mint-button">BUY</button>
        </div>

  </div>

      <div className="second-row">
        <div className="left-column">
          <h3>Experience Exclusive Benefits with Car Club Ownership!</h3>
          <p>By becoming a proud owner of Car Club collectibles at Driverz Inc., you unlock incredible advantages!</p>
          <p><strong>VROOM Token:</strong> Enjoy staking rewards coming soon! Once staking is available on our platform, you can earn tokens based on how many Car Club Collectibles are in your wallet: </p>
          <ul>
            <li>Own 1+ Car Club: Unlock staking for all collectibles.</li>
            <li>Own 7+ Car Club: Earn 1.5x the token earn rate for ALL collectibles.</li>
            <li>Own 15+ Car Club: Earn 2x the token earn rate for ALL collectibles.</li>
          </ul>
          <p><strong>Thrilling Raffles and Prizes:</strong> As a valued member of our community, you'll have the chance to participate in exciting raffles and win amazing prizes. Mint within the first 25% to enter the Early Bird Raffle, where three lucky winners can score $100 gift cards for Web3Wear merchandise. Additionally, we have cash giveaways at different percentage mint levels to reward our dedicated collectors.</p>

        </div>
        <div className="right-column">
          <img src={cClub4} alt='' />
        </div>
      </div>

      <div className="third-row">
        <div className="left-column">
          <img src={cClub_3} alt="" />
        </div>
        <div className="right-column">
          <h3>Driverz Car Club</h3>
          <p>Owning Driverz Car Club collectibles not only grants instant upgrades in the Driverz Racing League and access to a thrilling fantasy Formula 1 league but also unlocks the VROOM token. Exchange VROOM tokens on our website for local automotive, racing, and karting experiences! Additionally, redeem tokens for merchandise, digital collectibles, and cash rewards!<br /> <br />To start buying car club collectibles and enjoying the amazing benefits of all our Driverz collections, setup your account here.</p>
          {isCarClubInit ?
            <Alert key='success' variant='success' className="mb-0 custom-alert-text">
              Your account is already initialized.
            </Alert>
            :
            <Button variant="primary" onClick={initAllClick} className="mint-button">
              INITIALIZE
            </Button>
          }
          <ToastContainer />
        </div>
      </div>

      <div className="fourth-row">
        <div className="left-column">
          <h3>Mint Car Club</h3>
          <p>
            Mint a Car Club for just $37! <br/>            
          </p>
          <ul>
            <li>Total Collectibles: 5555</li>
            <li>Common tires: 4700</li>
            <li>Rare special tires: 285</li>
            <li>Rare rotating AVI tires: 525</li>
            <li>Sponsor tires: 15</li>
            <li>Legendary auto components: 30</li>
          </ul>
          <p>Select the quantity of Car Clubs you'd like to mint:</p>
          <InputGroup className="mb-3 padding-up center-item">
            <Button variant="primary" id="button-addon1" onClick={minusClick} className="minus-btn big-minus-button">
              -
            </Button>
            <Form.Control
              className="mint-count big-form-control"
              aria-label="Example text with button addon"
              aria-describedby="basic-addon1"
              value={mintCount}
              readOnly
            />
            <Button variant="primary" id="button-addon1" onClick={plusClick} className="custom-sign-in-btn big-plus-button">
              +
            </Button>
          </InputGroup>
          <div className="padding-up center-item">
            {user.addr !== null ?
              <Button variant="primary" onClick={mintCarClubClick} className="mint-button">
                {ownGenesis ? 
                <>
                  MINT {/*{mintCount} for ${mintCount * 37}*/}
                </>                      
                :
                <>
                  MINT {/*} {mintCount} for ${mintCount * 57}*/}
                </>
                }                    
              </Button>
            :
              <Button variant="primary" disabled className="mint-button">
                MINT {/* {mintCount} for ${mintCount * 57}*/ }
              </Button>
            }
          </div>
        </div>
        <div className="right-column">
          <img src={carClub2} alt="" />
        </div>
      </div>
{/*}
      <div className="fifth-row">
        <div className="left-column">
          <video autoPlay loop muted playsInline>
            <source src={cClub_rare_1} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="right-column">
          <h3>Driverz Insurance:<br></br> We've Got Your Back!</h3>
          <p>As passionate automobile racing fans, we understand the unpredictable nature of the digital collectible world. That's why Driverz Insurance is here to provide you, fellow racing enthusiasts, with an extra level of confidence when minting our new set.</p>
          <ul>
            <li>Mint: Acquire your Driverz Car Club.</li>
            <li>Insurance: Add a $10 insurance card to your collection.</li>
            <li>Limited Supply: Only 99 cards available, 1 per wallet.</li>
            <li>Validity: Card remains active for 30 days.</li>
            <li>Sell Back: Return your Driverz Car Club mints to us within the coverage period, no questions asked.</li>
            <li>Refund: Receive a refund of all but $7 per mint if unsatisfied with the project.</li>
            <li>No Claim Bonus: If no claim is processed within 30 days, enjoy an airdrop of one Genesis Driverz.</li>
          </ul>
          <p>As an additional bonus, 100% of the proceeds from Driverz Insurance will send 2 owners to an IRL racing, karting, or auto experience near them! Take the next step and secure your collection with Driverz Insurance today. Click the button below to purchase your insurance and join our community of racing enthusiasts.</p>
          {soldOut ? (
            <p>Insurance Cards are sold out.</p>
          ) : (
            <>
              {user.addr !== null && !ownInsurance ? (              
                <Button variant="primary" onClick={mintInsuranceClick} className="mint-button">
                  Get Insurance
                </Button>
              ) : user.addr === null ? (
                <p>Sign in to get insured!</p>
              ) : (
                <p>Thank you for your trust in Driverz Insurance!</p>
              )}
            </>
          )}
        </div>
      </div>
              */}
      <div className="sixth-row">
        <div className="left-column">
          <h3>Starter Pack [2OG/1CC]</h3>
          <h4><em>Perfect for beginning collectors!</em></h4>
          <p>Unwrap the thrill of DRIVERZ with TWO Genesis and ONE Car Club collectible. This pack provides everything you need to get started in our simulation leagues. Build your collection and showcase your passion for cars and racing. These items can be yours in our Starter Pack for just $49!</p>
          <button variant="primary" /* onClick={handlePurchaseClickSP} */  className="mint-button">SOLD OUT</button>
        </div>
        <div className="right-column">
          <video autoPlay loop muted playsInline>
            <source src={starterPack}  type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

        <div className="seventh-row">
          <div className="left-column">
            <video autoPlay loop muted playsInline>
              <source src={pitcrewPack} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="right-column">
            <h3>Pit Crew Pack [4OG]</h3>
            <h4><em>Build your crew!</em></h4>
            <p>Rev up your collection with FOUR Genesis collectibles. This pack is designed for avid collectors who can't get enough of our exclusive Genesis collectibles with the chance to pull Captain and Team (Matching) Driverz.  Grow your squad by 4 today for only $77! </p>
            <button variant="primary"  /*onClick={purchasePitCrewPack}*/  className="mint-button">SOLD OUT</button>
          </div>
        </div>

        <div className="eigth-row">
          <div className="left-column">
            <h3>Toolbox Bundle [4CC]</h3>
            <h4><em>Upgrade your tires!</em></h4>
            <p>Hit the grid with FOUR Car Club tires! This bundle is a must-have for holders that want the most from their driverz on race day. Upgrade your Genesis Driverz and generate more $VROOM with CC Tires! Click to secure the Toolbox Bundle for your collection today for $110!</p>
            <button variant="primary"  onClick={purchaseToolbox}  className="mint-button">Get the Toolbox Bundle Now!</button>
          </div>
          <div className="right-column">
            <video autoPlay loop muted playsInline>
              <source src={toolboxBundle} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
          

      
{/*
      <div className="row mt-5 mb-5">
        

        

        <div className="col-md-4 p-2">
          <Card bg="light" key="light" text="dark" className="mb-2">/*}
            {/*<Card.Img variant="top" src="./CarClub_Collection_2.png" />*/}
{/*            <Card.Body>
              <Card.Title>
                Driverz Car Club Collection Sale
              </Card.Title>
              <Card.Text>
                Click here to buy Driverz Car Club
              </Card.Text>

              <div className="row">
                <div className="col-6">
                  <InputGroup className="mb-3">
                    <Button variant="primary" id="button-addon1" onClick={minusClick}>
                      -
                    </Button>
                    <Form.Control
                      className="mint-count"
                      aria-label="Example text with button addon"
                      aria-describedby="basic-addon1"
                      value={mintCount}
                      readOnly
                    />
                    <Button variant="primary" id="button-addon1" onClick={plusClick}>
                      +
                    </Button>
                  </InputGroup>
                </div>

                <div className="col-6">
                  {user.addr !== null ?
                    <Button variant="primary" onClick={mintCarClubClick}>
                      {ownGenesis ? 
                      <>
                        Mint {mintCount} for ${mintCount * 37}
                      </>                      
                      :
                      <>
                        Mint {mintCount} for ${mintCount * 57}
                      </>
                      }                    
                    </Button>
                  :
                    <Button variant="primary" disabled>
                      Mint {mintCount} for ${mintCount * 57}
                    </Button>
                  }
                </div>
              </div>

              {/* <Button className="mx-3" variant="primary" onClick={unLinkClick}>
                Unlink
              </Button> 
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-md-6 p-2">
          {/*<img src="./Mint1.png" alt="" width="100%" height="auto" />*
        </div>
        <div className="col-md-6 p-2">
          {/*<img src="./Mint.png" alt="" width="100%" height="auto" />*
        </div>
      </div>

      {/*<h1 className="text-white text-center">
        Driverz Insurance
      </h1>
      <h3 className="text-white text-center">
        150 Policies Available
      </h3>
      <p className="text-white text-center mb-5">
        $10/Card, Max 1 Card per Holder
      </p>

      <p className="text-white">
        <b>Driverz Insurance</b>, We Got Your Back.<br/>
        Driverz Insurance was created with you, the collector in mind.  The NFT world can be unpredictable and we at Driverz want to give you an extra level of confidence when minting.
        <br/><br/>
        <b>How it Works</b>
        <br/>
        In short, after you mint your Driverz Car Club, add one insurance card to your collection for $10. Limited supply of 150 and only one per wallet is allowed.  
        The insurance card is valid for 30 days, and will give you the ability to sell your Driverz Car Club NFT back to us, no questions asked. Driverz will buy back your $77 mint 
        for $47 or your $97 mint for $67. You can trade in any amount of Driverz Car Club NFTs that you paid to mint. If you decide not to use your insurance and 
        hold your Driverz Car Club NFTs for more than the 30 day policy, you will be rewarded with your choice of a raffle ticket for an IRL 
        automotive experience near you, or a Driverz Genesis NFT. We will use 100% of the proceeds made from our Insurance policies to send 
        a few of our holders to their local race of choice. Simple, right?
        <br/><br/>
        <b>Why?</b><br/>
        Hey, life happens and we understand that here at Driverz.  Thirty days can seem like an eternity in the NFT world and some things may come up in real life.  
        We want to give our collectors a guaranteed cash offer to help out.  If you decide this project isn’t the right fit, that’s okay.  
        We want your NFTs and we want to get them into the hands of people who love the project. Driverz values you and the trust you showed us by minting, 
        and this insurance option is just one way we can say thank you.
            </p>*/}
{/*}
    <div className="row mt-5 mb-5">
        <div className="col-md-6">
        { /*<video width="100%" height="auto" controls>
            <source src="Driverz_Insurance.mp4"
              type="video/mp4" />
          </video>* 
        </div>      

        <div className="col-md-6">
          <Card bg="light" key="light" text="dark" className="mb-2">
            {/*<Card.Img variant="top" src="./DriverzInsuranceGold.png" />
            <Card.Body>
              {/*<Card.Title>
                Driverz Insurance Collection
              </Card.Title>
              <Card.Text>
                Click here to buy Driverz Insurance
              </Card.Text>*/}

              {/*(user.addr !== null && !ownInsurance) ?              
              <Button variant="primary" onClick={mintInsuranceClick}>
                Mint for $10
              </Button>
              :
              <Button variant="primary" disabled>
                Mint for $10
              </Button>
            
              
            </Card.Body>
          </Card>
        </div>
      </div> */}
    </div>
  )
}