import { useEffect, useState } from "react";
import { Col, Card, Button, Form } from "react-bootstrap";
//import { ReactCardFlip } from '../lib/react-card-flip';
import ReactCardFlip from "react-card-flip";
import * as fcl from "@onflow/fcl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NFTItem.css';
import { db } from '../firebaseNew'
import { collection, getDocs, doc, updateDoc, arrayUnion, deleteDoc, addDoc, getDoc} from "firebase/firestore"

import Wheel_Metadata from "../data/wheel_metadata.json";
import Helmet_Metadata from "../data/helmet_metadata.json";
import Tire_Metadata from "../data/tire_metadata.json";
import Car_Metadata from "../data/car_metadata.json";
import Genesis_Metadata from "../data/genesis_metadata.json";
import CarClub_Metadata from "../data/carclub_metadata.json";
import { createListingDapper } from "./marketplace/createListingDapper";
import { createListingHelmet } from "./marketplace/createListingHelmet";
import { createListingWheel } from "./marketplace/createListingWheel";
import { createListingCar } from "./marketplace/createListingCar";
import { createListingGensis } from "./marketplace/createListingGenesis";
import { revealCarClub } from "./carclub/revealCarClub";
import { removeListing } from "./marketplace/removeListing";
import { revealTire } from "./revealTire";

export default function NFTItem(props) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [genesisMetadata, setGenesisMetadata] = useState(null);
  const [carclubMetadata, setCarClubMetadata] = useState(null);
  const [price, setPrice] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [userAddress, setUserAddress] = useState(null)
  const [userDocId, setUserDocId] = useState(null);
  const [stakingInitialized, setStakingInitialized] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [isStaked, setIsStaked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stakedNftIds, setStakedNftIds] = useState([]);
  const [stakedNftTypes, setStakedNftTypes] = useState([]); // Example, replace with your initial values
  const [stakedCurrentDates, setStakedCurrentDates] = useState([]); // Example, replace with your initial values
  const [stakedCurrentTimes, setStakedCurrentTimes] = useState([]);

  //console.log(props.data.nftId);
  //console.log(props.isStaked);

  const usersCollectionRef = collection(db, "Staking-Users");
  // const isStaked = props.isStaked;
  var backCard = "";
  var overall = 0;

  //console.log("NFT Item Address: ", props.userAddress)

  useEffect(() => {

    const checkUserDocument = async () => {
      if (!props.userAddress) {
        console.log("Missing address.");
        return;
      }

      try {
        const querySnapshot = await getDocs(usersCollectionRef);
        const matchingDoc = querySnapshot.docs.find(
          (doc) => doc.data()["Wallet Address"] === props.userAddress
        );
  
        if (matchingDoc) {
          console.log("Matching document found with ID:", matchingDoc.id);
          setStakingInitialized(true);
          setUserDocId(matchingDoc.id);
        } else {
          console.log("No document found with user's wallet address.");
        }
      } catch (error) {
        console.error("Error checking document: ", error);
      }
    };

    checkUserDocument();
  }, []);

  const getCurrentDate = () => {
    var date = new Date(Date.now());
    var dateMDY = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return dateMDY;
  }

  const getFormattedTime = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getCurrentTime = () => {
    const date = new Date();
    const currentTimeString = getFormattedTime(date);
    return currentTimeString;
  };

const fetchStakedNftIds = async () => {
    try {
      const userDocRef = doc(db, 'Staking-Users', userDocId);
      const stakedNftsCollectionRef = collection(userDocRef, 'Staked-NFTs');
  
      const querySnapshot = await getDocs(stakedNftsCollectionRef);
  
      const stakedNftIds = querySnapshot.docs.map(doc => doc.data().nftId);
  
      console.log('Fetched stakedNftIds:', stakedNftIds);
      console.log('CurrentNFT ID:', props.data.nftId);
  
      setStakedNftIds(stakedNftIds);
      /*if (stakedNftIds.includes(props.data.nftId.toString())) {
        setIsStaked(!isStaked)
      }*/
      //setIsStaked(stakedNftIds.includes(props.data.nftId.toString()));
    } catch (error) {
      console.error('Error fetching staked NFT IDs: ', error);
    }
  };  

  useEffect(() => {
    // Check if the current NFT item is staked
    if (stakedNftIds.includes(props.data.nftId.toString())) {
      setIsStaked(true);
      console.log("isStaked?", isStaked);
    } else {
      setIsStaked(false);
      console.log("isStaked?", isStaked);
    }
  }, [stakedNftIds, props.data.nftId]);

  useEffect(() => {
    
    console.log("This nft id", props.data.nftId, "is staked?", isStaked);
  }, [isStaked]);



  useEffect(() => {
    // Fetch staked NFT IDs from the database
    const fetchStakedNftIds = async () => {
      try {
        const userDocRef = doc(db, 'Staking-Users', userDocId);
        const stakedNftsCollectionRef = collection(userDocRef, 'Staked-NFTs');
  
        const querySnapshot = await getDocs(stakedNftsCollectionRef);
  
        const stakedNftIds = querySnapshot.docs.map(doc => doc.data().nftId);
  
        console.log('Fetched stakedNftIds:', stakedNftIds);
  
        setStakedNftIds(stakedNftIds);


        // Check if the current NFT item is staked
        //setIsStaked(stakedNftIds.includes(props.data.nftId.toString()));

      } catch (error) {
        console.error('Error fetching staked NFT IDs: ', error);
      }
    };
  
    // Fetch staked NFT IDs and set isStaked state
    fetchStakedNftIds();
  }, [userDocId, props.data.nftId]);
  

  useEffect(() => {
    if (props.userAddress) {
      fetchStakedNftIds().then(() => {
        setIsStaked(stakedNftIds.includes(props.data.nftId.toString()));
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.userAddress, props.data.nftId, userDocId]);
  

  const handleStakeClick = async () => {
    // Get the current date and time
    const currentDate = getCurrentDate();
    const currentTime = getCurrentTime();

    // Get the NFT id and type
    const nftId = props.data.nftId;
    const nftType = props.data.nftType;

    // Print the information to console
    console.log('Current Date:', currentDate);
    console.log('Current Time:', currentTime);
    console.log('NFT ID:', nftId);
    console.log('NFT Type:', nftType);

    if (userDocId) {
      try {

        const userDocRef = doc(db, 'Staking-Users', userDocId);

        // Get the reference to the document using the userDocId
        const stakedNftsCollectionRef = collection(userDocRef, 'Staked-NFTs');

        // Update the document with the new data
        await addDoc(stakedNftsCollectionRef, {
          nftId: nftId,
          nftType: nftType,
          currentDate: currentDate,
          currentTime: currentTime,
        });

        console.log('Data saved to Firestore successfully.');
        setIsStaked(true);
        // Fetch the staked NFT IDs again to ensure the latest data
        fetchStakedNftIds();
      } catch (error) {
        console.error('Error updating document:', error);
      }
    } else {
      console.error('Missing userDocId. Cannot save data to Firestore.');
    }
  };

  const handleUnstakeClick = async () => {
    // Get the NFT ID to be unstaked
    const nftIdToUnstake = props.data.nftId.toString();
  
    try {
      // Find the index of the NFT ID in the nftIds array
      const userDocRef = doc(db, 'Staking-Users', userDocId);
      const stakedNftsCollectionRef = collection(userDocRef, 'Staked-NFTs');

      const querySnapshot = await getDocs(stakedNftsCollectionRef);
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
        if (data.nftId === nftIdToUnstake) {
          const stakedNftDocRef = doc.ref;

          await deleteDoc(stakedNftDocRef);

          setIsStaked(false);
        }
      });

      
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };


  useEffect(() => {

    if (props.data.nftType === "Car" && props.data.nftId <=45) {
      var temp = props.data.name.split("Car ");
      Car_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);

    } else if (props.data.nftType === "Car" && props.data.nftId > 45 ) {
      setThumbnail("../../assets/PackVideos/Starter_Pack.mp4");
      setIsVideo(true);
    

      
    } else if (props.data.nftType === "Wheel") {
      var temp = props.data.name.split("Steering Wheel ");
      Wheel_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Helmet") {
      var temp = props.data.name.split("Helmet ");
      Helmet_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Tires") {
      var temp = props.data.name.split("Tire ");
      Tire_Metadata.map((data) => {
        if (data.edition === +temp[1]) {
          setMetadata(data.attributes);
        }
      });

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if(props.data.nftType === "Genesis") {
      Genesis_Metadata.map((data) => {
        if (data.revealDisplay.name === props.data.name){
          setGenesisMetadata(data.metadata);
        }
      });     

      setThumbnail(props.data.thumbnail);
      setIsVideo(false);
    } else if (props.data.nftType === "Car Club") {
      CarClub_Metadata.map((data) => {
        if (data.Name === props.data.name){
          setCarClubMetadata(data);
        }
      });

      if(props.data.nftId >= 5001 && props.data.nftId <= 5525){
        setThumbnail("https://gateway.pinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%28"+ props.data.nftId +"%29.mp4");
        setIsVideo(true);
      }else{
        setThumbnail(props.data.thumbnail);
        setIsVideo(false);
      }
    }

  }, []);










  const profileClick = (name) => {
    setIsFlipped(!isFlipped);
  };

  const listClick = async (id) => {
    console.log("nftid - ", id);

    if(props.data.nftType === "Tires"){
      try {
        if(price === null){
          toast.error("Please type a price for a sale");
        }else{
          const res = await fcl.mutate({
            cadence: createListingDapper,
            args: (arg, t) => [
              arg(id, t.UInt64), //NFT ID
              arg(price + ".0", t.UFix64), // PRICE
              arg("0.1", t.UFix64), //ROYALTY
            ],
            limit: 9999,
          });
          toast.success("Tire is successfully listed!");
          console.log("res:", res);
        }      
      } catch (error) {
        // console.log("err:", error);
        toast.error(error);
      }
    }else if(props.data.nftType === "Helmet"){
      try {
        if(price === null){
          toast.error("Please type a price for a sale");
        }else{
          const res = await fcl.mutate({
            cadence: createListingHelmet,
            args: (arg, t) => [
              arg(id, t.UInt64), //NFT ID
              arg(price, t.UFix64), // PRICE
              arg("0.05", t.UFix64), //ROYALTY
            ],
            limit: 9999,
          });
          toast.success("Helmet is successfully listed!");
          console.log("res:", res);
        }      
      } catch (error) {
        // console.log("err:", error);
        toast.error(error);
      }
    }else if(props.data.nftType === "Wheel"){
      try {
        if(price === null){
          toast.error("Please type a price for a sale");
        }else{
          const res = await fcl.mutate({
            cadence: createListingWheel,
            args: (arg, t) => [
              arg(id, t.UInt64), //NFT ID
              arg(price, t.UFix64), // PRICE
              arg("0.05", t.UFix64), //ROYALTY
            ],
            limit: 9999,
          });
          toast.success("Wheel is successfully listed!");
          console.log("res:", res);
        }      
      } catch (error) {
        // console.log("err:", error);
        toast.error(error);
      }
    }else if(props.data.nftType === "Car"){
      try {
        if(price === null){
          toast.error("Please type a price for a sale");
        }else{
          const res = await fcl.mutate({
            cadence: createListingCar,
            args: (arg, t) => [
              arg(id, t.UInt64), //NFT ID
              arg(price, t.UFix64), // PRICE
              arg("0.05", t.UFix64), //ROYALTY
            ],
            limit: 9999,
          });
          toast.success("Car is successfully listed!");
          console.log("res:", res);
        }      
      } catch (error) {
        // console.log("err:", error);
        toast.error(error);
      }
    }else if(props.data.nftType === "Genesis"){
      try {
        if(price === null){
          toast.error("Please type a price for a sale");
        }else{
          console.log("id: ", id, "price: ", price)
          const res = await fcl.mutate({
            cadence: createListingGensis,
            args: (arg, t) => [
              arg("4407", t.UInt64), //NFT ID
              arg("25.0", t.UFix64), // PRICE
              arg("0.1", t.UFix64), //ROYALTY
            ],
            proposer: fcl.currentUser,
            payer: fcl.currentUser,
            authorizations: [fcl.currentUser],
            limit: 9999,
          });
          toast.success("Genesis is successfully listed!");
          console.log("res:", res);
        }      
      } catch (error) {
        // console.log("err:", error);
        toast.error(error);
      }
    }
    
  };

  const revealClick = async (id) => {
    console.log("Reveal Clicked - ID:", id); 
    try {
      const res = await fcl.mutate({
        cadence: revealCarClub,
        args: (arg, t) => [
          arg(id, t.UInt64), //NFT ID
        ],
        limit: 9999,
      });
  
      console.log('reveal - ', res);
      toast.success("Successfully revealed!");
    } catch (error) {
      toast.error(error);
    }    
  }
  const revealTireClick = async (id) => {
    try {
      const res = await fcl.mutate({
        cadence: revealTire,
        args: (arg, t) => [
          arg(id, t.UInt64), //NFT ID
        ],
        limit: 9999,
      });
  
      console.log('reveal - ', res);
      toast.success("Successfully revealed!");
    } catch (error) {
      toast.error(error);
    }    
  }

  const removeListingClick = async (id) => {
    console.log(id);
    try {
      const txid = await fcl.mutate({
        cadence: removeListing,
        args: (arg, t) => [
          arg(id, t.UInt64)
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });

      console.log(txid);
      toast.success("Successfully removed from listing!");
    } catch (error) {
      // console.log("err", error);
      toast.error(error);
    }
  }

  if (metadata) {
    backCard = (
      <div className="row font-13 mt-4">
        {metadata.map((data, index) => (
          <div key={index} className="col-6">
            <p className="text-white">
              {data.trait_type} <br /> {data.value}
            </p>
          </div>
        ))}
      </div>
    );
  }

  if (genesisMetadata) {
    overall = (parseInt(genesisMetadata.Mental) + parseInt(genesisMetadata.Luck) + 
      parseInt(genesisMetadata["Physical Stamina"]) + parseInt(genesisMetadata.Teamwork))/4;

    backCard = 
      <div className="gen-backcard-container">

          <div className="trait-row">
            <p className="text-white">
             <span>Team: </span> &nbsp; {genesisMetadata.Team}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white">
              <span>Background: </span> &nbsp; {genesisMetadata.Background}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white">
              <span>Helmet: </span> &nbsp; {genesisMetadata.Helmet}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white">
              <span>Suit: </span> &nbsp; {genesisMetadata.Suit}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white">
              <span>Special Skill:</span> &nbsp; {genesisMetadata["Special Skill"]}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white">
              <span>Luck:</span> &nbsp; {genesisMetadata.Luck}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white mb-3">
              <span>Mental:</span> &nbsp; {genesisMetadata.Mental}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white mb-3">
              <span>Teamwork:</span> &nbsp; {genesisMetadata.Teamwork}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white mb-3">
              <span>Physical Stamina:</span> &nbsp; {genesisMetadata["Physical Stamina"]}
            </p>
          </div>

          <div className="trait-row">
            <p className="text-white mb-3">
              <span>Overall: </span> &nbsp; {overall}
            </p>
          </div>
      </div>
  }

  if (carclubMetadata) {
    backCard = 
      <div className="cc-backcard-container">

        <div className="trait-row">
          <p className="text-white">
            <span>Car Part:  </span> &nbsp; {carclubMetadata["Car Part"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white">
            <span> Part Rarity: </span> &nbsp; {carclubMetadata["Part Rarity"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white">
            <span>Background: </span> &nbsp; {carclubMetadata["Background"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white">
            <span>Tire Compound: </span> &nbsp; {carclubMetadata["Tire Compound"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white">
            <span>Tire Tread: </span> &nbsp; {carclubMetadata["Tire Tread"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white">
            <span>Tire Design:</span> &nbsp; {carclubMetadata["Tire Design"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Rim Style:</span> &nbsp; {carclubMetadata["Rim Style"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Logo Format:</span> &nbsp; {carclubMetadata["Logo Format"]}
          </p>
        </div>

        {/*<div className="col-6">
          <p className="text-white mb-3">
            LEGENDARY VERSION <br /> {carclubMetadata["Legendary Version"]}
          </p>
        < /div>*/}

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Speed: </span> &nbsp; {carclubMetadata["Speed"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Braking: </span> &nbsp; {carclubMetadata["Braking"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Passing: </span> &nbsp; {carclubMetadata["Passing"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Turning: </span> &nbsp; {carclubMetadata["Turning"]}
          </p>
        </div>

        <div className="trait-row">
          <p className="text-white mb-3">
            <span>Staking %:</span> &nbsp; {carclubMetadata["Staking %"]}
          </p>
        </div>

      </div>      
  }

  // console.log(props.data);

  return (
    <div className='col-xxl-2 col-xl-3 col-md-4 col-sm-6 p-3'>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card className="p-3" bg={"dark"}>
          <div className="card-content">
            <div className="card-img-row">
              {isVideo ?
              <video width="100%" height="auto" className='media'controls>
                <source src={thumbnail} type="video/mp4" />
              </video>
              
              :
              <Card.Img variant="top" className='media' src={thumbnail} />
              }   
            </div>
            <div className="card-name-row">    
              <h5 className="text-center mt-2 font-style">{props.data.name}</h5>
            </div>
            <div className="card-button-row">  
              {props.data.nftType === "Car Club" ?
                <>              
                  {props.data.thumbnail === "https://ipfs.io/ipfs/QmPWCRKuzQqhwAjtC2RjUCQJZgcc42yBrhMHgA9YNJihjv/Placeholder.png" ?
                    <>
                      <div className="button-container">
                        <p
                          className="custom-text text-center mb-2 mt-2"
                          onClick={() => profileClick(props.data.name)}
                        >
                          See Profile
                        </p>
                        <p
                          // variant="warning"
                          className="custom-text text-center mb-2 mt-2"
                          onClick={() => revealClick(props.data.nftId)} 
                                
                        >
                          Reveal
                        </p> 
                        {stakingInitialized && (
                          isStaked ? (
                            <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleUnstakeClick}>
                              Unstake
                            </p>
                          ) : (
                            <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleStakeClick}>
                              Stake
                            </p>
                          )
                        )}
                      </div>
                      </>
                    :
                      <>
                        <div className="button-container">
                          <p
                            className="custom-text text-center mb-2 mt-2"
                            role="button"
                            onClick={() => profileClick(props.data.name)}
                          >
                            See Profile
                          </p>
                          {stakingInitialized && (
                            isStaked ? (
                              <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleUnstakeClick}>
                                Unstake
                              </p>
                            ) : (
                              <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleStakeClick}>
                                Stake
                              </p>
                            )
                        )}
                        </div>
                      </>
                    
                  }            
                </>
              :
                <>
                  <div className="button-container">
                    <p
                      className="custom-text text-center mb-2 mt-2"
                      onClick={() => profileClick(props.data.name)}
                      role="button"
                    >
                      See Profile
                    </p>
                    {stakingInitialized && (
                      isStaked ? (
                        <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleUnstakeClick}>
                          Unstake
                        </p>
                      ) : (
                        <p className="custom-text text-center mb-2 mt-2" role="button" onClick={handleStakeClick}>
                          Stake
                        </p>
                      )
                    )}
                  </div>
                  {/*{props.data.listed ?
                    <>
                      <div className="button-container">
                        <p className="text-white text-center mt-1">
                          Listed for ${parseInt(props.data.price)}
                        </p>
                        <Button variant="warning" className="mt-2"
                          onClick={() => removeListingClick(props.data.listingId)}>
                          Remove Listing
                        </Button> 
                      </div>                 
                    </>                
                  :
                    <>

                      <Form.Control type="number" placeholder="Type price for sale" 
                        onChange = {e => {
                          const enteredPrice = Number(e.target.value);
                          const adjustedPrice = (enteredPrice * 1.05).toFixed(8);
                          setPrice(enteredPrice)
                          }}/>

                      {props.id ?
                      <Button
                        variant="warning"
                        className="mt-3"
                        onClick={() => listClick(props.id)}
                      >
                        List for Sale
                      </Button>
                      :
                      <Button
                        variant="warning"
                        className="mt-3"
                        onClick={() => listClick(props.data.nftId)}
                      >
                        List for Sale
                      </Button>
                      }                  
                    </>
                  } */}             
                </>            
              }      
            </div> 
            {/* <Button variant="warning" className="mt-3" onClick={() => transferClick(props.data.id)}>
              Transfer to new Owner
            </Button> */}
          </div>
        </Card>

        <Card className="p-3" bg={"dark"}>
          <div className="backcard-container">
            <div className="backcard-title">
              <h5 className="text-center font-style mt-4 mb-3">
                {props.data.name}
              </h5>
            </div>

            {backCard}

            {props.data.traits && 
              <>
                
                <div className="trait-row">
                  <p className="text-white">
                    CAR PART  {props.data.traits["Car Part"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    PART RARITY <br /> {props.data.traits["Part Rarity"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    BACKGROUND <br /> {props.data.traits["Background"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    TIRE COMPOUND <br /> {props.data.traits["Tire Compound"]}
                  </p>
                </div>
              
                <div className="trait-row">
                  <p className="text-white">
                    TIRE TREAD <br /> {props.data.traits["Tire Tread"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    TIRE DESIGN <br /> {props.data.traits["Tire Design"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    RIM STYLE <br /> {props.data.traits["Rim Style"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    LOGO FORMAT <br /> {props.data.traits["Logo Format"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    LEGENDARY VERSION <br /> {props.data.traits["Legendary Version"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    STAKING % <br /> {props.data.traits["Staking %"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    SPEED <br /> {props.data.traits["Speed"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    BRAKING <br /> {props.data.traits["Braking"]}
                  </p>
                </div>
                
                <div className="trait-row">
                  <p className="text-white">
                    PASSING <br /> {props.data.traits["Passing"]}
                  </p>
                </div>

                <div className="trait-row">
                  <p className="text-white">
                    TURNING <br /> {props.data.traits["Turning"]}
                  </p>
                </div>
                
              </>
            }
            <div className="backcard-button">
              <p
                className="custom-text text-center mb-0 font-weight-bold"
                onClick={() => profileClick(props.data.name)}
                role="button"
              >
                Back
              </p>
            </div>
          </div>
        </Card>
      </ReactCardFlip>
    </div>
  );
}
