export const revealCarClub = `
import NonFungibleToken from 0x1d7e57aa55817448
import CarClub from 0xf887ece39166906e

transaction (id: UInt64){
    let userCap: &CarClub.Collection

    prepare(user: AuthAccount) {
        self.userCap = user.borrow<&CarClub.Collection>(from: CarClub.CollectionStoragePath)!
        let nft <- self.userCap.withdraw(withdrawID: id) as! @CarClub.NFT
        
        nft.revealThumbnail()
        self.userCap.deposit(token: <-nft)
  }
}
`