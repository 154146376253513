export const purchaseInsurance = `
import FungibleToken from 0xf233dcee88fe0abe
import DapperUtilityCoin from 0xead892083b3e2c6c
import NonFungibleToken from 0x1d7e57aa55817448
import MetadataViews from 0x1d7e57aa55817448
import DriverzInsurance from 0xf887ece39166906e
import DriverzInsuranceStorefront from 0xf887ece39166906e

// This transaction purchases an NFT on a peer-to-peer marketplace (i.e. **not** directly from a dapp). This transaction
// will also initialize the buyer's NFT collection on their account if it has not already been initialized.
// FIRST ARGUMENT OF A P2P PURCHASE TRANSACTION SHOULD ALWAYS BE THE SELLER'S ADDRESS
transaction(merchantAccountAddress: Address, storefrontAddress: Address, listingResourceID: UInt64,  expectedPrice: UFix64) {
    let paymentVault: @FungibleToken.Vault
    let nftCollection: &NonFungibleToken.Collection{NonFungibleToken.Receiver}
    let storefront: &DriverzInsuranceStorefront.Storefront{DriverzInsuranceStorefront.StorefrontPublic}
    let listing: &DriverzInsuranceStorefront.Listing{DriverzInsuranceStorefront.ListingPublic}
    let salePrice: UFix64
    let balanceBeforeTransfer: UFix64
    let mainDapperUtilityCoinVault: &DapperUtilityCoin.Vault

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        // Initialize the buyer's collection if they do not already have one
        if buyer.borrow<&DriverzInsurance.Collection>(from: DriverzInsurance.CollectionStoragePath) == nil {
            let collection <- DriverzInsurance.createEmptyCollection() as! @DriverzInsurance.Collection
            buyer.save(<-collection, to: DriverzInsurance.CollectionStoragePath)
            
            buyer.link<&DriverzInsurance.Collection{NonFungibleToken.CollectionPublic, DriverzInsurance.CollectionPublic, MetadataViews.ResolverCollection}>(
                DriverzInsurance.CollectionPublicPath,
                target: DriverzInsurance.CollectionStoragePath
            )
             ?? panic("Could not link collection Pub Path");
        }

        // Get the storefront reference from the seller
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&DriverzInsuranceStorefront.Storefront{DriverzInsuranceStorefront.StorefrontPublic}>(
                DriverzInsuranceStorefront.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Get the listing by ID from the storefront
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        self.salePrice = self.listing.getDetails().salePrice

        // Get a DUC vault from Dapper's account
        self.mainDapperUtilityCoinVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from account storage")
        self.balanceBeforeTransfer = self.mainDapperUtilityCoinVault.balance
        self.paymentVault <- self.mainDapperUtilityCoinVault.withdraw(amount: expectedPrice)

        // Get the collection from the buyer so the NFT can be deposited into it
        self.nftCollection = buyer.borrow<&NonFungibleToken.Collection{NonFungibleToken.Receiver}>(
            from: DriverzInsurance.CollectionStoragePath
        ) ?? panic("Cannot borrow NFT collection receiver from account")
    }

    // // Check that the price is right
    pre {
        self.salePrice == expectedPrice || self.salePrice - 0.0 == expectedPrice : "unexpected price"
    }

    execute {
        self.listing.purchase(
            payment: <-self.paymentVault, 
            collection: self.nftCollection
        )

        // Remove listing-related information from the storefront since the listing has been purchased.
        self.storefront.cleanup(listingResourceID: listingResourceID)
    }

    // Check that all dapperUtilityCoin was routed back to Dapper
    post {
        self.mainDapperUtilityCoinVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }
}
`